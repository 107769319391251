import React from 'react'
import { css } from '@emotion/core'
import homesTargetSrc from '../images/homes-target-home.svg'

export default () => {
  return (
    <div
      css={theme => css`
        margin: 0 auto;
        width: 600px;
        max-width: 100%;
        border-radius: ${theme.radius}px;
        position: relative;
        @media (min-width: ${theme.breakpoints.medium}px) {
          text-align: center;
          padding: 24px 34px 90px;
          border: 1px solid ${theme.colors.primaryTint};
          background: #fff;
          box-shadow: 0 12px 32px 4px ${theme.colors.primary}1F;
        }
      `}
    >
      <h1 className="heading heading--1" style={{ margin: 0 }}>
        Targeting <strong>attractive returns</strong> from property investment
      </h1>
      <div
        css={css`
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
        `}
      >
        <img
          src={homesTargetSrc}
          alt="Targeting attractive returns from property investment"
          css={theme => css`
            display: none;
            @media (min-width: ${theme.breakpoints.medium}px) {
              display: block;
            }
            margin-left: auto;
            margin-right: auto;
          `}
        />
      </div>
    </div>
  )
}

import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'

import Headline from './HomeHeadline'

export default () => (
  <section
    css={theme => css`
      background: linear-gradient(to bottom, ${theme.colors.tint1}8C 10%, #fff);
      @media (min-width: ${theme.breakpoints.medium}px) {
        text-align: center;
      }
    `}
  >
    <div className="container" style={{paddingTop: 16}}>
      <div css={theme => css`
        margin-top: 16px;
        @media (min-width: ${theme.breakpoints.medium}px) {
          margin-top: -52px;
        }
      `}>
        <Headline />
      </div>
      <p
        css={theme => css`
          margin-top: 24px;
          @media (min-width: ${theme.breakpoints.medium}px) {
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
          }
          font-size: 1.9rem;
          max-width: 470px;
        `}
      >
        Our experienced team locate and negotiate the purchase of suitable
        freehold properties for clients, then organise refurbishments intended
        to improve the capital value or return on investment.
      </p>
      <p
        css={css`
          margin-top: 40px;
          font-size: 1.4rem;
        `}
      >
        Capital at risk and rates are not guaranteed.
        {/* Please read our <Link to="/" style={{ color: 'inherit'}}>important information page</Link> before investing. */}
      </p>
    </div>
  </section>
)

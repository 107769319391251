import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import brickBgTileSrc from '../images/bricks-bg-tile.svg'
import PlanItem from './HowPlanWorksItem'
import acquireIconSrc from '../images/acquire-homes-icons.svg'
import plansIconSrc from '../images/icons/plans.svg'
import decorateIconSrc from '../images/icons/decorate.svg'
import saleBoardIconSrc from '../images/icons/sale-board.svg'
import returnsIconSrc from '../images/icons/coins.svg'
import repeatIconSrc from '../images/icons/loop.svg'

const baselineGraphicCss = css`
  margin: 0 0 -1px;
  display: flex;
  align-items: flex-end;
`

export default () => {
  return (
    <Section>
      <div className="container" style={{ textAlign: 'center' }}>
        <h2 className="heading heading--1">
          How the{' '}
          <TitleBranding>
            <strong>No Loss</strong> Properties
          </TitleBranding>{' '}
          Plan Works
        </h2>
        <ItemContainer>
          <PlanItem
            title="Acquire"
            graphic={
              <div css={baselineGraphicCss}>
                <img
                  src={acquireIconSrc}
                  alt="Acquire property at the best prices"
                />
              </div>
            }
            text={
              <p>
                We locate and negotiate the purchase of suitable freehold
                properties at the best price.
              </p>
            }
          />
          <PlanItem
            title="Refurbish"
            graphic={
              <div
                css={css`
                  display: grid;
                  grid-template-columns: repeat(2, auto);
                  grid-column-gap: 16px;
                  padding-top: 4px;
                `}
              >
                <img
                  src={plansIconSrc}
                  alt="Property refurbishment planning icon"
                />
                <img src={decorateIconSrc} alt="Property decoration icon" />
              </div>
            }
            text={
              <p>
                Refurbishment varies for each property but is likely to include
                kitchen/bathroom, electrical works and redecorating.
              </p>
            }
          />
          <PlanItem
            title="Sell or Rent"
            subtitle="You Decide"
            graphic={
              <div css={baselineGraphicCss}>
                <img
                  src={saleBoardIconSrc}
                  alt="For Sale property board icon"
                />
              </div>
            }
            text={
              <p>
                We aim to sell properties within 6 months or we can introduce
                you to a specialist lettings and management agent.
              </p>
            }
          />
          <PlanItem
            title="Returns"
            graphic={
              <div>
                <img
                  src={returnsIconSrc}
                  alt="Property investment returns icon"
                />
              </div>
            }
            text={
              <>
                <p>
                  <strong>If you sell</strong>, you will be covered by our
                  Cash-backed Downside Protection.
                </p>
                <p>
                  <strong>If you rent</strong>, we will introduce an agent
                  offering a Rent Guarantee.
                </p>
              </>
            }
          />
          <PlanItem
            title="Repeat"
            graphic={
              <div>
                <img
                  src={repeatIconSrc}
                  alt="Repeat property investment icon"
                />
              </div>
            }
            text={
              <p>
                Investors can use the plan <br />
                again and again...
              </p>
            }
          />
        </ItemContainer>
      </div>
    </Section>
  )
}

const TitleBranding = styled.span`
  color: ${p => p.theme.colors.primary};
  strong {
    font-weight: 600;
  }
`

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 100px;
  border-top: 2px solid #f2ede7;
  background: url(${brickBgTileSrc}) ${p => p.theme.colors.tint1}8c;
  background-size: 720px auto;
  background-position: center center;
  background-attachment: fixed;
`

const ItemContainer = styled.div`
  margin-top: 100px;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  justify-content: space-evenly;
  justify-items: center;
  align-items: start;
  grid-gap: 40px 24px;

  @media (min-width: ${p => p.theme.breakpoints.medium}px) {
    grid-template-columns: repeat(2, auto);
    grid-row-gap: 80px;

    > :nth-child(even) {
      position: relative;
      top: 50%;
    }
  }
`

import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Intro from '../components/HomeIntro'
import HowPlanWorks from '../components/HomeHowPlanWorks'
import KeyBenefits from '../components/HomeKeyBenefits'
import PropertyExamples from '../components/PropertyExamples'
import WhoPlanFor from '../components/WhoPlanFor'
import Cta from '../components/Cta'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Intro />
    <HowPlanWorks />
    <KeyBenefits />
    <PropertyExamples />
    <WhoPlanFor />
    <Cta />
  </Layout>
)

export default IndexPage

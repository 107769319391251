import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import propertyBelongsToYouSrc from '../images/icons/money-hands.svg'
import downsideProtectionIconSrc from '../images/icons/umbrella.svg'
import rentalReturnsIconSrc from '../images/icons/coins.svg'

import Panel from './KeyBenefitPanel'

export default () => {
  return (
    <Section>
      <div className="container">
        <h2
          className="heading heading--1"
          css={css`
            text-align: center;
            margin-bottom: 48px;
          `}
        >
          Key Benefits
        </h2>
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <Panel
            landscape
            title={
              <>
                The property always <br />
                <strong>belongs to you</strong>
              </>
            }
            graphic={
              <img
                src={propertyBelongsToYouSrc}
                alt="Property belongs to you icon"
              />
            }
          >
            <p>
              We only get paid from the sale proceeds after you have received
              the return of your initial capital invested in the property or
              have decided to rent out the property.
            </p>
          </Panel>
        </div>
        <div style={{ textAlign: 'center' }}>
          <div
            css={css`
              max-width: 480px;
              margin: 120px auto 40px;
            `}
          >
            <h3
              className="heading heading--3"
              css={theme => css`
                color: ${theme.colors.primary};
                strong {
                  font-size: 1.1em;
                }
              `}
            >
              <strong>Reduced risk</strong> <br />
              whether you sell or rent
            </h3>
            <p>
              The No Loss Properties plan has been designed to offer investors
              significant protection in the event of unforeseen circumstances,
              whether you intend to sell or rent.
            </p>
          </div>
          <div
            css={css`
              display: inline-flex;
              flex-flow: row wrap;
              justify-content: center;
              > * {
                flex: 0 0 auto;
                margin: 16px;
              }
            `}
          >
            <Panel
              title={
                <>
                  Downside <br />
                  protection
                </>
              }
              graphic={
                <img
                  src={downsideProtectionIconSrc}
                  alt="Downside Protection icon"
                />
              }
            >
              <p>
                The plan offers investors cash-backed protection for 20% of the
                property’s initial basic purchase price until the property has
                been sold and you have recouped that initial purchase price plus
                the cost of the refurbishments.
              </p>
            </Panel>
            <Panel
              title={
                <>
                  Minimum rental
                  <br />
                  returns assured
                </>
              }
              graphic={
                <img
                  src={rentalReturnsIconSrc}
                  alt="Minimum rental returns assured"
                />
              }
            >
              <p>
                If you decide to rent your property, we will introduce you to a
                trusted letting and management agent who will offer a 6% gross
                yield per annum Rent Guarantee in place of the Downside
                Protection.
              </p>
            </Panel>
          </div>
        </div>
      </div>
    </Section>
  )
}

const Section = styled.section`
  background: ${p => p.theme.colors.tint2};
  padding-top: 80px;
  padding-bottom: 100px;
  border-top: 1px solid #f2ede7;
`

import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Property from './PropertyExamplesBlock'
import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  const {
    ashington,
    countyDurham,
    durham,
    southShields,
  } = useStaticQuery(graphql`
    {
      ashington: file(
        relativePath: { eq: "properties/Ashington_detached 100k.jpg" }
      ) {
        ...propertyPhoto
      }
      countyDurham: file(
        relativePath: { eq: "properties/County Durham 80k.jpg" }
      ) {
        ...propertyPhoto
      }
      durham: file(
        relativePath: { eq: "properties/Durham_detached 100k.jpg" }
      ) {
        ...propertyPhoto
      }
      southShields: file(
        relativePath: { eq: "properties/South Shields_detached 100k.jpg" }
      ) {
        ...propertyPhoto
      }
    }

    fragment propertyPhoto on File {
      childImageSharp {
        fixed(width: 300, height: 300, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `)

  return (
    <Section>
      <div className="container">
        <header
          css={css`
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
          `}
        >
          <h3
            css={css`
              text-transform: uppercase;
              font-size: 2.8rem;
              margin-bottom: 0;
              letter-spacing: 0.04em;
            `}
          >
            Example properties
          </h3>
          <p style={{ marginTop: 10, fontSize: '2.2rem' }}>
            Properties that can be purchased for less than £100,000
          </p>
        </header>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(auto, 340px));
            grid-gap: 32px;
            justify-content: center;
            justify-items: center;
            margin-top: 40px;
          `}
        >
          <Property
            image={durham.childImageSharp}
            alt="Durham investment property"
          />
          <Property
            image={ashington.childImageSharp}
            alt="Ashington investment property"
          />
          <Property
            image={southShields.childImageSharp}
            alt="South Shields investment property"
          />
          <Property
            image={countyDurham.childImageSharp}
            alt="County Durham investment property"
          />
        </div>
      </div>
    </Section>
  )
}

const Section = styled.section`
  background: ${p => p.theme.colors.primary};
  color: #fff;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 100px;
`

import React from 'react'
import { css } from '@emotion/core'

export default ({ title, graphic, children, landscape }) => {
  return (
    <div
      css={theme => css`
        max-width: ${landscape ? 640 : 300}px;
        @media (min-width: ${theme.breakpoints.medium}px) {
          ${landscape && 'display: flex'};
        }
        align-items: center;
        text-align: center;
        border: 2px solid ${theme.colors.primary};
        border-radius: ${theme.radius}px;
        padding: ${landscape ? '32px 40px' : '32px'};
        background: #fff;
      `}
    >
      <div
        css={theme => css`
          flex: 1 0 auto;
          @media (min-width: ${theme.breakpoints.medium}px) {
            ${landscape && 'margin-right: 24px'};
          }
        `}
      >
        <div
          css={css`
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              height: 100%;
            }
          `}
        >
          {graphic}
        </div>
        <h4
          className="heading heading--4"
          css={theme => css`
            line-height: 1.4;
            font-weight: 400;
            color: ${theme.colors.primary};
            margin-top: 16px;
            @media (min-width: ${theme.breakpoints.medium}px) {
              margin-bottom: ${landscape ? 0 : 16}px;
            }
            strong {
              font-weight: 600;
            }
          `}
        >
          {title}
        </h4>
      </div>
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          max-width: 300px;
          display: flex;

          > *:first-of-type {
            margin-top: 0;
          }
          > *:last-of-type {
            margin-bottom: 0;
          }
        `}
      >
        {children}
      </div>
    </div>
  )
}

import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import brickBgTileSrc from '../images/bricks-bg-tile.svg'
import planItemContainerBgSrc from '../images/plan-item-container-bg.svg'
import investorsIconSrc from '../images/icons/people.svg'

export default () => {
  return (
    <Section>
      <div
        className="container"
        css={css`
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          flex-flow: row wrap;
        `}
      >
        <div
          css={theme => css`
            background-image: url(${planItemContainerBgSrc});
            background-size: 100% 100%;
            padding: 96px;
            margin-top: 32px;
            margin-bottom: 32px;
            @media (min-width: ${theme.breakpoints.large}px) {
              margin-right: 40px;
            }
          `}
        >
          <img src={investorsIconSrc} alt="Investors icon" />
        </div>
        <div
          css={css`
            max-width: 480px;
          `}
        >
          <h2 className="heading heading--2">Who is the Plan Intended for?</h2>
          <p>
            This plan is designed for investors who may not wish to spend large
            amounts on individual properties but are looking for above average
            returns.
          </p>
          <p>
            The Downside Protection and project management make it especially
            suitable for consideration by both relatively inexperienced
            investors and those from overseas.
          </p>
          <p>
            Investors can use this plan again and again and be confident in the
            knowledge that they have a highly experienced team at their side.
          </p>
        </div>
      </div>
    </Section>
  )
}

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 100px;
  border-top: 2px solid #f2ede7;
  background: url(${brickBgTileSrc}) ${p => p.theme.colors.tint1}8c;
  background-size: 720px auto;
  background-position: center center;
  background-attachment: fixed;
`

import React from 'react'
import styled from '@emotion/styled'
import Image from 'gatsby-image'

export default ({ image, alt }) => {
  return (
    <Block>
      <Image {...image} alt={alt} style={{ display: 'block', borderRadius: '4px' }} />
    </Block>
  )
}

const Block = styled.section`
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
`

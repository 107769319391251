import React, { useState } from 'react'
import Panel from './HowPlanWorksPanel'
import { css } from '@emotion/core'
import planItemContainerBgSrc from '../images/plan-item-container-bg.svg'
import { Waypoint } from 'react-waypoint'
import { motion } from 'framer-motion'

const variants = {
  offscreen: {
    y: 100,
  },
  onscreen: {
    y: 0,
  },
}

const childVariants = {
  offscreen: {
    y: 30,
    scale: 0.8,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    scale: 1,
    opacity: 1,
  },
}

export default ({ title, subtitle, graphic, text }) => {
  const [onscreen, setOnscreen] = useState(false)

  return (
    <Waypoint bottomOffset={200} onEnter={() => setOnscreen(true)}>
      <div>
        <motion.div
          animate={onscreen ? 'onscreen' : 'offscreen'}
          initial="offscreen"
          variants={variants}
          transition={{
            duration: 0.3,
            staggerChildren: 0.15,
            delayChildren: 0.1,
          }}
          css={theme => css`
            @media (min-width: ${theme.breakpoints.large}px) {
              padding: 96px 96px 64px;
              background-image: url(${planItemContainerBgSrc});
              background-size: 100% 100%;
            }
          `}
        >
          <div
            css={css`
              max-width: 240px;
              flex-direction: column;
              display: flex;
              align-items: center;
            `}
          >
            <motion.div variants={childVariants}>
              <Panel title={title} subtitle={subtitle} children={graphic} />
            </motion.div>

            <motion.div
              variants={childVariants}
              css={css`
                font-weight: 500;
                font-size: 1.6rem;
              `}
            >
              {text}
            </motion.div>
          </div>
        </motion.div>
      </div>
    </Waypoint>
  )
}

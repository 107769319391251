import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

export default () => {
  return (
    <aside
      css={theme => css`
        background: ${theme.colors.primary};
        color: #fff;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 80px;
      `}
    >
      <div
        className="container"
        css={css`
          display: flex;
          flex-flow: row wrap;
          justify-content: space-evenly;
          max-width: 720px;
          > * {
            flex-basis: 300px;
            padding: 8px 16px;
          }

          .button {
            margin-bottom: 16px;
          }
        `}
      >
        <div>
          <h4 className="heading heading--2">Learn More</h4>
          <Link className="button button--expand" to="/faqs">
            FAQs
          </Link>
          <a className="button button--expand" href="/no-loss-properties-brochure.pdf" download="No Loss Properties Brochure.pdf">
            Brochure
          </a>
        </div>
        <div>
          <h4 className="heading heading--2">Enquiries</h4>
          <a
            href="mailto:hello@nolossproperties.com"
            className="button button--expand"
            to="/"
          >
            Email Enquiry
          </a>
          {/* <Link className="button button--expand" to="/">
            Enquiry Form
          </Link> */}
          <div>
            <a
              style={{ color: '#fff', fontSize: '2.4rem' }}
              href="tel:+44-0-203-858-7333"
            >
              +44 (0)203 858 7333
            </a>
          </div>
        </div>
      </div>
    </aside>
  )
}

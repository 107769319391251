import React from 'react'
import { css } from '@emotion/core'

export default ({ title, subtitle, children }) => {
  return (
    <div
      css={theme => css`
        border: 1px solid ${theme.colors.primaryTint};
        border-radius: ${theme.radius}px;
        background: #fff;
        width: 220px;
        min-height: 140px;
        box-shadow: 0 12px 40px ${theme.colors.primary}26;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
      `}
    >
      <h3
        css={theme => css`
          flex: 0;
          margin: 16px 0 8px;
          text-transform: uppercase;
          color: ${theme.colors.primary};
          letter-spacing: 0.05em;
          font-size: 1.6rem;
          .subtitle {
            font-weight: 400;
          }
        `}
      >
        {title}
        {subtitle && <br />}
        {subtitle && <span className="subtitle">{subtitle}</span>}
      </h3>
      <div css={css`
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: stretch;
      `}>{children}</div>
    </div>
  )
}
